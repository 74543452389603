import axios, { AxiosResponse } from 'axios';
import { Countries } from '../entities/countries.entity';
import { Services } from '../hooks/services.hook';
import { ApiResult } from '../interfaces';

type CompanyServiceType = {
	getCompanyCountries: (id: number) => Promise<ApiResult<Countries[]>>;
	getCompanyServices: (id: number) => Promise<Partial<Services>>;
};

const getCompanyCountries = async (id: number): Promise<ApiResult<Countries[]>> => {
	const response: AxiosResponse<ApiResult<Countries[]>> = await axios.get(`/v2/companies/${id}/companyCountries`);
	return response.data;
};
const getCompanyServices = async (id: number): Promise<Partial<Services>> => {
	const response: AxiosResponse<Partial<Services>> = await axios.get(`/v2/companies/${id}/services/status`);
	return response.data;
};

const CompanyService: CompanyServiceType = {
	getCompanyCountries,
	getCompanyServices,
};

export default CompanyService;

import { translate } from '@optimusgps/optimus-intl';
import { message } from 'antd';
import copy from 'copy-to-clipboard';
import { ROLES } from '../constants/global';
import { CopyToClipBoard, ValidateMultimedia } from './utilities.type';

// export const minimunValidationForMultimedia = ({
// 	roles,
// 	shared = false,
// 	isImpersonating = false,
// }: ValidateMultimedia): boolean =>
// 	!isImpersonating && !shared && (roles.includes(ROLES.VIDEO_CAMERA) || roles.includes(ROLES.CUSTOMER_SUPPORT));

export const shouldDisplayMultimediaIcons = ({
	roles,
	shared = false,
	isImpersonating = false,
	impersonatorRoles = [],
}: ValidateMultimedia): boolean => {
	if (shared) {
		return false;
	}
	if (isImpersonating) {
		return (
			roles.includes(ROLES.VIDEO_CAMERA) &&
			(impersonatorRoles.includes(ROLES.CUSTOMER_SUPPORT) ||
				impersonatorRoles.includes(ROLES.SERVICE_PROVIDER) ||
				impersonatorRoles.includes(ROLES.MASTER))
		);
	}
	return roles.includes(ROLES.VIDEO_CAMERA);
};

export const shouldPlayMultimediaVideos = ({
	roles,
	shared = false,
	isImpersonating = false,
}: ValidateMultimedia): boolean => !shared && !isImpersonating && roles.includes(ROLES.VIDEO_CAMERA);

export const capitilizeFirstLetter = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1);

export const copyToClipBoard = ({ text, intl, successMessage, errorMessage }: CopyToClipBoard) => {
	if (copy(text)) {
		if (successMessage) {
			message.success(successMessage);
		} else {
			message.success(intl.formatMessage(translate('successCopy')));
		}
	} else {
		if (successMessage) {
			message.error(errorMessage);
		} else {
			message.error(intl.formatMessage(translate('badCopy')));
		}
	}
};
